import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from "../../server/interceptors";
import { apiAdditionalMaterialSearchUrl, DEFAULT_LANGUAGE} from '../../config';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import DynamicChecklist  from '../../components/dynamicChecklist';
import DynamicTable from '../../components/dynamicTableDiagnosticLevels';
import { onSubjectCall } from '../../server/getLessonSubjectCall/lesson-subject-call';
import { onGradeCall } from '../../server/getLessonGradesCall/lesson-grade-call';
import {onDiagnosticTestStatusCall, getDiagnosticSubjectLevelsCall} from '../../server/diagnosticsCall/diagnostics-call';
import {FaSearch, FaBook, FaBookOpen, FaPlus, FaPlusCircle} from 'react-icons/fa'
import SpinnerLoader from '../../components/spinnerLoader/spinner-loader';
import ModalPopout from '../../components/modalPopout/modal-popout-view';
import ModalDiagnosticCreate from './modal-diagnostic-create';

class DiagnosticPage extends Component{

    constructor(props){
        super(props);
        this.state={
            loading: false,
            subjectsFromServer: [],
            gradesFromServer: [],
            statusFromServer: [],
            selectedGrades: [],
            selectedAddresses: [],
            selectedSubjects: [],
            selectedStatus: [],
            tableData: [{}],
            tablePage: 1,
            limitPage: 10,
            totalItems: 0,
            orderBy: "ASC",
            nextButtonStatus: false,
            previousButtonStatus: false,
            selectedStandardId: "",
            selectedExpectationId: "",
            selectedSubCodeId: "",
            selectedSubMainSubCodeId: "",
            showSelect: typeof props.showSelect !== 'undefined' ? props.showSelect : false,
            lessonId: typeof props.lessonId !== 'undefined' ? props.lessonId : null,
            subjectOpen: false,
            gradeOpen: false,
            statusOpen: false,
            codeOpen: false,
            projectOpen: false,
            modalCreateOpen: false
        }
        this.onChange = this.onChange.bind(this);
        this.onChangeOrderAscending = this.onChangeOrderAscending.bind(this);
        this.onChangeOrderDescending = this.onChangeOrderDescending.bind(this);
        this.onDataCall = this.onDataCall.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.buttonStatusRefresh = this.buttonStatusRefresh.bind(this);
        this.toggleSubject = this.toggleSubject.bind(this);
        this.toggleGrade = this.toggleGrade.bind(this);
        this.toggleStatus = this.toggleStatus.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.closeCreateModal = this.closeCreateModal.bind(this);
    }

    onChange(field, value){
        //Trick to add to state from multiple inputs with same function
        this.setState({[field]: value});
    }

    toggleSubject(){
        this.setState({subjectOpen: !this.state.subjectOpen});
    }

    toggleGrade(){
        this.setState({gradeOpen: !this.state.gradeOpen});
    }
    toggleStatus(){
        this.setState({statusOpen: !this.state.statusOpen});
    }

    onChangeOrderAscending() {
        this.setState({
            orderBy: "ASC"
        }, function(){
            this.onDataCall();
        })
    }
    onChangeOrderDescending() {
        this.setState({
            orderBy: "DESC"
        }, function(){
            this.onDataCall();
        })
    }
    openCreateModal(){
        this.setState({modalCreateOpen: true});
    }

    closeCreateModal(){
        this.setState({modalCreateOpen: false});
      }

    onDataCall(tablePage){
        this.setState({loading: true}, function(){
            let self = this;
            
            const {
                selectedSubjects,
                selectedGrades,
                selectedStatus,
                limitPage
                } = this.state;

            const params = {
                "SubjectCodes": selectedSubjects,
                "LevelCodes": selectedGrades,
                "StatusCodes": selectedStatus,  
                "FromPage": tablePage ?? 1,
                "Limit": limitPage,
            };

            this.onDiagnosticSubjectLevels(params, function(response){
                self.setState({
                    tableData: response.items,
                    tablePage: tablePage ?? 1,
                    totalItems: response.total,
                    loading: false
                }, function () {
                    self.buttonStatusRefresh();
                });
            });//Refreshing button statuses
        });
    }

    onDiagnosticSubjectLevels(params, onSuccess) {
        getDiagnosticSubjectLevelsCall(params).then(function (response) {
            const data = {
                items: [],
                total: 0
            }

            if (typeof response.Items !== undefined &&
                response.Items !== null &&
                response.Items.length > 0) {

                const diagnosticSubjectLevels = response.Items.map((item) => ({
                    "Id": item.Id,
                    "SubjectName": item.SubjectName,
                    "LevelName": item.LevelName,
                    "PreTestStatus": item.PreTestStatusDescription,
                    "PostTestStatus": item.PostTestStatusDescription,
                    "linkItems": [
                        {
                            "diagnosticSubjectLevelId": item.Id,
                            "link": "./edit-diagnostic-test"
                        }
                    ]
                }));
                
                data.items = diagnosticSubjectLevels;
                data.total = response.Total;
            }
        
            if(onSuccess) {
                onSuccess(data);
            }

        }).catch(error => {
            console.log(error.response.data.Messages);
        });
    }

    nextPage(){
        this.setState({
            tablePage: this.state.tablePage + 1
        }, function() {
            this.onDataCall(this.state.tablePage);
            this.buttonStatusRefresh();
        });
    }

    previousPage(){
        this.setState({
            tablePage: this.state.tablePage - 1
        }, function(){
            this.onDataCall(this.state.tablePage);
            this.buttonStatusRefresh();
        });
    }

    buttonStatusRefresh = () => {
        // Checking if back button can go back
        if(this.state.tablePage === 1){
            this.setState({
                previousButtonStatus: true
            });
        } else{
            this.setState({
                previousButtonStatus: false
            });
        }
        if(this.getLastPage() === this.state.tablePage){
            this.setState({
                nextButtonStatus: true
            });
        } else{
            this.setState({
                nextButtonStatus: false
            });
        }
    }

    //Getting the last possible page
    getLastPage = () => {
        if(this.state.totalItems%10 > 0){
            return Math.floor(this.state.totalItems/10) + 1;
        } else {
            return this.state.totalItems/10;
        }
    }

    componentDidMount(){

        onSubjectCall(this.props.language).then(data => {
            this.setState({subjectsFromServer: data});
        });
        onGradeCall(this.props.language).then(data => {
            this.setState({gradesFromServer: data});
        });
        onDiagnosticTestStatusCall(this.props.language).then(data => {
            this.setState({statusFromServer: data});
        });
        this.buttonStatusRefresh();
    }


    render(){

        return(
            <Container fluid className="card-alt">
                <Row>
                    <Col xs={10}>
                        <h3>
                            <FaSearch/><FormattedMessage id="d-title"/>{' '}
                        </h3>
                    </Col>
                    <Col xs={2}>
                        <Button variant="primary" type="submit" onClick={this.openCreateModal}> <FaPlusCircle/>{' '}<FormattedMessage id="d-add-combination"/></Button>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md="auto">
                        <h4><FormattedMessage id="vl-filters"/></h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            variant={this.state.subjectOpen ? "primary" : "outline-primary"}
                            onClick={this.toggleSubject}
                            aria-controls="set-subject-collapse"
                            aria-expanded={this.state.subjectOpen}>
                            <FormattedMessage id="vl-subject"/>
                        </Button>
                        {' '}
                        <Button
                            variant={this.state.gradeOpen ? "primary" : "outline-primary"}
                            onClick={this.toggleGrade}
                            aria-controls="set-grade-collapse"
                            aria-expanded={this.state.gradeOpen}>
                            <FormattedMessage id="vl-grade"/>
                        </Button>
                        {' '}
                        <Button
                            variant={this.state.statusOpen ? "primary" : "outline-primary"}
                            onClick={this.toggleStatus}
                            aria-controls="set-grade-collapse"
                            aria-expanded={this.state.statusOpen}>
                            <FormattedMessage id="vl-status"/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Collapse in={this.state.subjectOpen}>
                        <div id="set-subject-collapse" className='mt-3'>
                            <b><FormattedMessage id="vl-choose-subject"/></b>
                            <DynamicChecklist
                                items={this.state.subjectsFromServer}
                                parentState={this.state.selectedSubjects}
                                stateName="selectedSubjects"
                                onChange={this.onChange}
                                backgroundColor="#007bff"
                            />
                        </div>
                    </Collapse>
                    <Collapse in={this.state.gradeOpen}>
                        <div id="set-grade-collapse" className='mt-3'>
                            <b><FormattedMessage id="vl-choose-grade"/></b>
                            <DynamicChecklist
                                items={this.state.gradesFromServer}
                                parentState={this.state.selectedGrades}
                                stateName="selectedGrades"
                                onChange={this.onChange}
                                backgroundColor="#007bff"
                            />
                        </div>
                    </Collapse>
                    <Collapse in={this.state.statusOpen}>
                        <div id="set-status-collapse" className='mt-3'>
                            <b><FormattedMessage id="d-choose-status"/></b>
                            <DynamicChecklist
                                items={this.state.statusFromServer}
                                parentState={this.state.selectedStatus}
                                stateName="selectedStatus"
                                onChange={this.onChange}
                                backgroundColor="#007bff"
                            />
                        </div>
                    </Collapse>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col>
                        <Button variant="primary" type="submit" onClick={() => this.onDataCall() }> <FaSearch/>{' '}<FormattedMessage id="vl-search"/></Button>
                    </Col>
                </Row>
                
                {/* If there are entries for the table, display it */}
                {this.state.loading ? <div style={{marginTop: "40px"}}><SpinnerLoader/></div>
                    :
                    <div>
                        {this.state.totalItems > 0 &&
                        <div>
                            <hr/>
                            <div className="tableControls">
                                <p style={{textAlign: 'right'}}>
                                    <FormattedMessage id="vl-results"/> :  {this.state.totalItems}
                                </p>
                            </div>
                            <DynamicTable
                                data={this.state.tableData}
                                itemsPerPage={10}
                                language={this.props.language}
                                pageNumber={this.state.tablePage}
                                changePage={(direction) => {this.onChangePage(direction)}}
                                resultQuantity={this.state.totalItems}
                                showSelect={this.state.showSelect}
                            />
                            <div className="pageControls">
                                {/* Next page buttons */}
                                <Button disabled={this.state.previousButtonStatus} onClick={this.previousPage}>{'<'}</Button>
                                {this.state.tablePage}
                                <Button disabled={this.state.nextButtonStatus} onClick={this.nextPage}>{'>'}</Button>
                            </div>
                        </div>
                        }
                    </div>
                }
                <ModalDiagnosticCreate 
                    modalOpen={this.state.modalCreateOpen}
                    closeModal={this.closeCreateModal}
                    language={this.props.language}
                    loadDiagnostics={this.onDataCall}
                    />
            </Container>
        );
    }
}

export default DiagnosticPage;
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./edit-diagnostictest-page-style.css";
import { Col, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpinnerLoader from '../../components/spinnerLoader/spinner-loader';
import {getDiagnosticSubjectLevelDetailsCall} from "../../server/diagnosticsCall/diagnostics-call";

class EditDiagnosticTestPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: sessionStorage.diagnosticSubjectLevelId,
            subjectName: "",
            levelName: "",
            preTestStatusDescription: "",
            postTestStatusDescription: "",
            diagnosticTests: [],
            redirect: false,
            preTest: {},
            postTest: {},
        };
        this.redirect = this.redirect.bind(this);
        this.goToQuiz = this.goToDiagnosticPrePostTest.bind(this);
    }

    onChange(field, value) {
        //Trick to add to state from multiple inputs with same function
        this.setState({ [field]: value });
    }

    componentDidMount() {
        this.onDataCall();
    }

    goToDiagnosticPrePostTest(id, type) { 
        sessionStorage.setItem("diagnosticTestId", id);
        sessionStorage.setItem("diagnosticType", type);
    }

    getTestIdByType(diagnosticTests, type) {
        for (let i = 0; i < diagnosticTests.length; i++) {
            if (diagnosticTests[i].DiagnosticType === type) {
                return {
                    id: diagnosticTests[i].Id,
                    status: diagnosticTests[i].Status,
                };
            }
        }
        return {
            id: "",
            status: 0,
        };
    }

    onDataCall() {
        this.setState({ loading: true });
        getDiagnosticSubjectLevelDetailsCall(this.state.id)
            .then((response) => {
                this.setState({
                    subjectName: response.SubjectName,
                    levelName: response.LevelName,
                    diagnosticTests: response.DiagnosticTests,
                    preTestStatusDescription: response.PreTestStatusDescription,
                    postTestStatusDescription: response.PostTestStatusDescription,
                    /* DiagnosticType 1 Pretest 2 PostTest */
                    preTest: this.getTestIdByType(response.DiagnosticTests, 1),
                    postTest: this.getTestIdByType(response.DiagnosticTests, 2),
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error.response.data.Messages);
            });
    }

    redirect() {
        this.setState({ redirect: true });
    }

    getActionLinkByTestStatus(status) {
        switch (status) {
            case 0:
                return "am-create";
            case 1:
                return "qp-viewOrEdit";
            default:
                return "nav-view";
        }
    }

    getCardColorByTestStatus(status) {
        switch (status) {
            case 0:
                return "#F4C6C6";
            case 1:
                return "#C8E6FA";
            default:
                return "#C8E7C0";
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/diagnostics" />;
        }
        return ( this.state.loading === false ?
            <Container fluid className="card-alt">
                <Row>
                    <Col>
                        <h2>
                            <FormattedMessage id="d-title" />
                        </h2>
                    </Col>
                </Row>
                <hr></hr>
                <Row className="mb-3">
                    <Col xs={3} md={2}>
                        <b>
                            <FormattedMessage id="codes-subject" />
                        </b>
                    </Col>
                    <Col xs={9} md={10}>
                        <p>{this.state.subjectName}</p>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={3} md={2}>
                        <b>
                            <FormattedMessage id="vl-grade" />
                        </b>
                    </Col>
                    <Col xs={9} md={10}>
                        <p>{this.state.levelName}</p>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col xs={5} md={3}>
                        <Card key={1} style={{ width: "18rem", marginTop: "5px", backgroundColor: this.getCardColorByTestStatus(this.state.preTest.status) }}>
                            <Card.Body>
                                <Card.Title>
                                    <FormattedMessage id="vl-pre" />
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    <FormattedMessage id="vl-status" />:{" "}
                                    {this.state.preTestStatusDescription ?? ""} <br />
                                    Quiz ID: {this.state.preTest.id ?? ""} <br />
                                </Card.Subtitle>
                                <Card.Text className="mb-2 text-muted">
                                    {/* Something we can user for some text inside the card */}
                                </Card.Text>
                                <Link
                                    key={1}
                                    onClick={() => this.goToDiagnosticPrePostTest(this.state.preTest.id, 1)}
                                    to={{ pathname: "edit-diagnostic-prepost-test" }}
                                    style={{ color: "#457DC5" }}
                                >
                                    <FormattedMessage id={this.getActionLinkByTestStatus(this.state.preTest.status)} />
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={7} md={3}>
                        <Card key={1} style={{ width: "18rem", marginTop: "5px", backgroundColor: this.getCardColorByTestStatus(this.state.postTest.status) }}>
                            <Card.Body>
                                <Card.Title>
                                    <FormattedMessage id="vl-post" />
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    <FormattedMessage id="vl-status" />:{" "}
                                    {this.state.postTestStatusDescription ?? ""} <br />
                                    Quiz ID: {this.state.postTest.id ?? ""} <br />
                                </Card.Subtitle>
                                <Card.Text className="mb-2 text-muted">
                                    {/* Something we can user for some text inside the card */}
                                </Card.Text>
                                <Link
                                    key={1}
                                    onClick={() => this.goToDiagnosticPrePostTest(this.state.postTest.id, 2)}
                                    to={{ pathname: "edit-diagnostic-prepost-test" }}
                                    style={{ color: "#457DC5" }}
                                >
                                    <FormattedMessage id={this.getActionLinkByTestStatus(this.state.postTest.status)} />
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        : <div style={{marginTop: "40px"}}><SpinnerLoader/></div> );
    }
}

export default EditDiagnosticTestPage;

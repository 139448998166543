import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import TextAreaInput from '../textAreaInput';
import TinyMCE from '../tinyMCE';

import Button from 'react-bootstrap/Button';

import FileDropzone from '../dropzoneImplementation';
import  Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import MediaPreview from '../mediaPreview/media-preview-view';

import {onRulesCall} from "../../server/getFileUploadRulesCall/file-upload-rules-call";
import SpinnerLoader from "../spinnerLoader";



class RichTextInput extends Component{
  constructor(props){
    super(props);
    this.state={
      uploadShow: false,
      size: typeof props.size !== 'undefined' && props.size !== null ? props.size : 480,
      fileRules: this.props.fileRules,
      showFileUpload: typeof props.showFileUpload !== 'undefined' && props.showFileUpload !== null ? props.showFileUpload : true
    }
    this.toggleShow = this.toggleShow.bind(this);

  }

  componentDidMount() {
    // If fil rules is not defined, then read the rules in the backend.
    if(typeof this.state.fileRules === 'undefined' || this.state.fileRules === null || this.state.fileRules === []){
        onRulesCall()
        .then((response) => {
            this.setState({fileRules: response});
        })
        .catch((error) => {
            console.log("Error getting file rules", error);
        });
    }
  }

  toggleShow(){
    if(this.props.subject === null || this.props.grade === null){
      alert("Grade and Subject are needed to upload content");
    } else{
      this.setState({uploadShow: !this.state.uploadShow});
    }
  }

  render(){

    const { limitFromRules, content } = this.props;

    if(typeof this.state.fileRules === 'undefined' || this.state.fileRules === null || this.state.fileRules === []){
      return <SpinnerLoader/>
    }

    return(
      <div>
        {this.props.labelID ? <b><FormattedMessage id={this.props.labelID}/></b> : " "}
        {this.props.isValidated ? <span className="text-danger ml-2"><FormattedMessage id="va-lesson-richtext"/></span> : ""}
        {this.props.isVR ? <TextAreaInput isForVR textType="textarea" parentStateKey={this.props.textName}  onChange={this.props.onChange} content={this.props.content}/> : 
          <div>
            <TinyMCE index={this.props.index} parentStateKey={this.props.textName} content={this.props.content} onChange={this.props.onChange} size={this.state.size}/>
            {
              limitFromRules ? 
                  <p className='small' style={ content ? content.length > limitFromRules ? {color: "red"} : {} : {}}>{content ? content.length : 0} / {limitFromRules}</p>
                : 
                  ""
            }
            {this.state.showFileUpload ?
                <>
            <div style={{display: "flex"}}>
              <Button 
                style={{marginLeft: "auto", marginTop: "15px"}} 
                onClick={this.toggleShow}
                aria-controls="uploadShow"
                aria-expanded={this.state.uploadShow}>
                  <FontAwesomeIcon icon={faShare} color="#dddddd"/>
                  <FormattedMessage id="button-toggle-show"/>
              </Button>
            </div>
            <Collapse in={this.state.uploadShow}>
              <div id="uploadShow" className='Region-ButtonAudio'>
                <FileDropzone 
                  parentStateKey={this.props.textName} 
                  content={this.props.content}
                  onChange={this.props.onChange} 
                  language={this.props.language}
                  grade={this.props.grade}
                  subject={this.props.subject}
                  fileRules={this.state.fileRules}
                  isQuiz={this.props.isQuiz}
                />
              </div>
            </Collapse>
            <MediaPreview
              content={this.props.content}
            />
                </> : null }
          </div>

        }
        
      </div>
    );
  }
}

export default RichTextInput;
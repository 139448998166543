import React from "react";
import {FaFile} from "react-icons/fa";
import {FormattedMessage} from "react-intl";
import LearningSheetSimpleSearch from "./learning-sheet-simple-search/learning-sheet-simple-search";

/**
 * The view learning sheet page.
 * @returns {JSX.Element}
 * @constructor
 */
const ViewLearningSheetPage = () => {

    const handleSelectedSheet = (sheet) => {
        console.log("Sheet Parent", sheet);
    }

    return (
        <>
            <h3><FaFile/>
                <FormattedMessage id="ls-title"/>
                </h3>
            <hr/>
            <LearningSheetSimpleSearch updateParent={handleSelectedSheet} showSelectedButton={false} />
        </>
    );
};

export default ViewLearningSheetPage;
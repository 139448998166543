import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  apiGetQuizRules
} from "../../config";
import axios from "../../server/interceptors";
import { onRulesCall } from "../../server/getFileUploadRulesCall/file-upload-rules-call";
import { decodeHTMLEntities } from "../../utils/htmlEntityDecoder";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import LessonHTMLViewer from "../../components/lessonHTMLViewer";
import TabNavigator from "../../components/tabNavigator";

//Local Components
import { FaSave, FaArrowLeft } from "react-icons/fa";
import SessionManager from "../../utils/authHandling/session-manager";
import SpinnerLoader from "../../components/spinnerLoader";
import "./edit-diagnostic-prepost-test-page-style.css";
import DiagnosticPrePostTestInfo from "./components/diagnosticPrePostTestInfo";
import DiagnosticPrePostQuestions from "./components/diagnosticPrePostQuestions";
import { getDiagnosticSubjectLevelDetailsCall, createDiagnosticTestCall } from "../../server/diagnosticsCall/diagnostics-call";
import { Redirect } from "react-router-dom";

class EditDiagnosticPrePostTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Diagnostic Test Info
      diagnosticTestType: parseInt(sessionStorage.diagnosticType),
      diagnosticSubjectLevelId: sessionStorage.diagnosticSubjectLevelId,
      diagnosticTestId: sessionStorage.diagnosticTestId,
      subjectName: "",
      levelName: "", 
      diagnosticTest: {},
      //For questions
      quizQuestionModel: [],
      quizQuestionRubricModelList: [],
      quizQuestionResponseModelList: [],
      loading: false,
      redirect: false,
      previewId: null
    };
    this.onChange = this.onChange.bind(this);
    this.getDiagnosticSubjectLevelDetails = this.getDiagnosticSubjectLevelDetails.bind(this);
    this.getTitleText = this.getTitleText.bind(this);
    this.saveDiagnosticTest = this.saveDiagnosticTest.bind(this);
    this.getQuizRules = this.getQuizRules.bind(this);
    this.removePreviewId = this.removePreviewId.bind(this);
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  getDiagnosticTestIdByType(diagnosticTests, type) {
    for (let i = 0; i < diagnosticTests.length; i++) {
        if (diagnosticTests[i].DiagnosticType == type) {
            return diagnosticTests[i];
        }
    }
    return null;
}

  getDiagnosticSubjectLevelDetails() {
    this.setState({ loading: true });
    
    getDiagnosticSubjectLevelDetailsCall(this.state.diagnosticSubjectLevelId, true)
    .then((response) => {
        const diagnosticTest = this.getDiagnosticTestIdByType(response.DiagnosticTests, this.state.diagnosticTestType);
        this.setState({
            loading: false,
            subjectName: response.SubjectName,
            levelName: response.LevelName,
            diagnosticTest: diagnosticTest,
            lessonGrade: response.LevelCode,
            lessonSubject: response.SubjectCode,
            quizQuestionModel: diagnosticTest.Questions.map((question) => ({ Desc: question.Description })),
            quizQuestionResponseModelList: diagnosticTest.Questions.map((question) => question.Responses.map((response) => ({ Response: response.Description, Correct: `${response.IsCorrect}` })),
            )
        });
    })
    .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
    });
  }

  /**
   * Function to save the diagnostic test
   * @param {number} operation - 1: Save, 2: Publish
   */
  saveDiagnosticTest(operation) {  
    const newQuestions = this.state.quizQuestionModel;
    const newAnswers = this.state.quizQuestionResponseModelList;
    const { intl } = this.props;

    if(operation === 2 && (newQuestions === undefined || newQuestions === null || newQuestions.length < 7)) {
      alert(intl.formatMessage({ id: "d-publish-validation" }));
      return;
    }

    const questions = this.buildDiagnosticTestQuestions(newQuestions, newAnswers);

    this.setState({ 
      loading: true,
      diagnosticTest: {
        ...this.state.diagnosticTest,
        Status: operation,
        Questions: questions
      }
    }, function () {
      createDiagnosticTestCall(this.props.language, this.state.diagnosticTest)
      .then(() => {
        if(operation === 2) {
          this.setState({ loading: false, redirect: true });
        }
        else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        alert(error.response.data.Messages);
        this.setState({ loading: false });
      });
    });
  }

  buildDiagnosticTestQuestions(newQuestions, newAnswers) {
    const questions = newQuestions.map((newQuestion, index) => {
      const question = {
        Description: decodeHTMLEntities(newQuestion.Desc),
        Responses: []
      };
      
      newAnswers[index].forEach((newAnswer, c) => {
        question.Responses.push({
          Description: decodeHTMLEntities(newAnswer.Response),
          IsCorrect: newAnswer.Correct
        });
      });

      return question;
    });

   return questions; 
  }

  getQuizRules() {
    let self = this;
    axios
      .get(
        apiGetQuizRules +
        "/" +
        this.state.quizType +
        "/" +
        sessionStorage.lastSearch,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          self.setState({
            quizRules: response.data.Rules[0],
          });
        }
      })
      .catch((error) => {
        alert(
          error.response.data.Messages,
          "Quiz rules could not be retrieved from server."
        );
      });
  }

  componentDidMount() {
    // this.getQuiz();
    this.getDiagnosticSubjectLevelDetails();
    onRulesCall().then((data) => {
      this.setState({ fileRules: data });
    });
  }
  removePreviewId() {
    this.setState({ previewId: null });
  }

  getTitleText() {
    switch(this.state.diagnosticTestType ?? 0) {
      case 1: return <FormattedMessage id="vl-pre" />;
      case 2: return <FormattedMessage id="vl-post" />;
    }
  }


  render() {
    if (this.state.redirect) {
      return <Redirect to="/edit-diagnostic-test" />;
    }
    
    if (this.state.loading) {
      return <SpinnerLoader />;
    }

    const sections = [
      {
        sectionTitle: "Info",
        sectionContent: (
          <DiagnosticPrePostTestInfo
            onChange={this.onChange}
            state={this.state}
            language={this.props.language}
            fileRules={this.state.fileRules}
          />
        ),
      },
      {
        sectionTitle: "Preguntas",
        sectionContent: (
          <DiagnosticPrePostQuestions
            onChange={this.onChange}
            state={this.state}
            language={this.props.language}
            fileRules={this.state.fileRules}
          />
        ),
      },
    ];

    return (
      <Container fluid className="card-alt">
        <h4>
          { this.getTitleText() }
        </h4>
        <Row>
          <Col xs={12}>
            <Link to="edit-diagnostic-test">
              <Button
                style={{ marginBottom: "10px", marginLeft: "5px" }}
                variant="warning"
              >
                <FaArrowLeft /> <FormattedMessage id="d-go-back" />
              </Button>
            </Link>

            { (!this.state.diagnosticTest || !this.state.diagnosticTest.Status || this.state.diagnosticTest.Status === 1) && 
              (<><Button
                className="btn-save-test"
                style={{ marginBottom: "10px" }}
                onClick={() => this.saveDiagnosticTest(1)}
              >
                <FaSave /> <FormattedMessage id="d-save-test" />
              </Button>
              <Button
              className="btn-publish-test"
                style={{ marginBottom: "10px" }}
                onClick={() => this.saveDiagnosticTest(2)}
              >
                <FaSave /> <FormattedMessage id="d-publish-test" />
              </Button></>)
            }
          </Col>
        </Row>
        <div>
          <Row>
            <Col xs={12} md={8}>
              <h3>{`${this.state.subjectName} ${this.state.levelName}`}</h3>
            </Col>
          </Row>
    
          <hr />
        </div>
        <TabNavigator sections={sections} defaultActive="Info" />
        <div
          style={{
            bottom: "0",
            right: "0",
            position: "fixed",
            textAlign: "center",
            zIndex: "100",
          }}
        >
          <FontAwesomeIcon
            onClick={() => this.setState({ previewId: 5 })}
            icon={faEye}
            size="4x"
            color="#fff"
            style={{
              backgroundColor: "rgb(88, 145, 153)",
              height: "70px",
              width: "70px",
              padding: "5px",
              borderRadius: "100%",
              margin: "10px",
            }}
          />
          <p>
            <FormattedMessage id="preview" />
          </p>

          <LessonHTMLViewer
            contentId={this.state.previewId}
            lessonId={this.state.diagnosticTestId}
            language={this.props.language}
            removePreviewId={this.removePreviewId}
          />
        </div>
      </Container>
    );
  }
}

export default injectIntl(EditDiagnosticPrePostTest);

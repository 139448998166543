import axios from "../interceptors";
import { apiLearningSheet } from "../../config";

const headers = {
  "Content-Type": "application/json",
};

/**
 * To get all learning sheets.
 * @param id To filter by id.
 * @param keyWord To filter by keyword.
 * @param fromPage From which page to start.
 * @param limiter The number of items to get.
 * @returns {Promise<any>}
 */
export const getLearningSheets = async (id, keyWord, fromPage, limiter) => {
    const params = new URLSearchParams();

    if (id) params.append("id", id);
    if (keyWord !== null && keyWord !== '') params.append("keyWord", keyWord);
    if (fromPage) params.append("pageFrom", fromPage);
    if (limiter) params.append("limiter", limiter);

    const url = `${apiLearningSheet}?${params.toString()}`;

    const response = await axios.get(url, {
        headers: headers,
    });
    return response.data;
};

/**
 * To create a learning sheet.
 * @param keyWord The keyword of the learning sheet.
 * @param textDescription The description of the learning sheet.
 * @param textImportant The important text of the learning sheet.
 * @returns {Promise<any>}
 */
export const postLearningSheet = async (keyWord, textDescription, textImportant ) => {

    const obj = {
        "KeyWord": keyWord,
        "TextDescription": textDescription,
        "TextImportance": textImportant
    }

  const response = await axios.post(`${apiLearningSheet}`, obj, {
    headers: headers,
  });
  return response.data;
};

/**
 * To moqify a learning sheet.
 * @param id The id of the learning sheet.
 * @param keyWord The keyword of the learning sheet.
 * @param textDescription The description of the learning sheet.
 * @param textImportant The important text of the learning sheet.
 * @returns {Promise<any>}
 */
export const putLearningSheet = async (id, keyWord, textDescription, textImportant ) => {

    const obj = {
        "Id": id,
        "KeyWord": keyWord,
        "TextDescription": textDescription,
        "TextImportance": textImportant
    }
    let url = `${apiLearningSheet}/${id}`;
    const response = await axios.put(url, obj, {
        headers: headers,
    });
    return response.data;
};

export const deleteLearningSheet = async (id) => {
    const url = `${apiLearningSheet}/${id}`;
    const response = await axios.delete(url, {
        headers: headers,
    });
    return response.data;
}
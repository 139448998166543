import React, { Component } from 'react';
import {Col, Row, Container} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import { createDiagnosticSubjectLevelCall } from '../../server/diagnosticsCall/diagnostics-call';
import RadioButtonSubjects from '../../components/radioButtonSubjects/radio-button-subjects';
import RadioButtonGrades from '../../components/radioButtonGrades/radio-button-grades';
import { FaSave, FaArrowLeft } from "react-icons/fa";
class ModalDiagnosticCreate extends Component{
  
  constructor(props){
    super(props);
    this.state={
      lessonSubject: "",
      lessonGrade: "",
      validateLessonSubject: false,
    };
    
    this.createDiagnosticSubjectLevel = this.createDiagnosticSubjectLevel.bind(this);
    this.sendComment = this.sendDiagnostic.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  sendDiagnostic(diagnosticSubjectLevelModel){
    if(createDiagnosticSubjectLevelCall(this.props.language, diagnosticSubjectLevelModel).then(() => {
      this.props.loadDiagnostics();
    })){
      this.onCloseModal();
    }
  }

  createDiagnosticSubjectLevel(){
    const diagnosticSubjectLevelModel = {
      SubjectCode: this.state.lessonSubject,
      LevelCode: this.state.lessonGrade
    };
    let isCompleted = true;
    //User
    if(diagnosticSubjectLevelModel.SubjectName === ""){
      this.setState({validateLessonSubject: true});
      isCompleted = false;
    }
    //Message
    if(diagnosticSubjectLevelModel.LevelCode === ""){
      this.setState({validateLessonGrade: true});
      isCompleted = false;
    }

    if(isCompleted){
      this.sendDiagnostic(diagnosticSubjectLevelModel);
    }
  }

  onChange(field, value){
    this.setState({[field]: value});
  }

  onCloseModal(){
    this.setState({
      lessonSubject: null,
      lessonGrade: "",
      validateLessonSubject: false,
      validateLessonGrade: false
    }, () => this.props.closeModal());
  }

  render(){


    return(
      <div>
        <Modal show={this.props.modalOpen} onHide={this.onCloseModal} size="sm">
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <RadioButtonSubjects
                textName="lessonSubject"
                onChange={this.onChange}
                content={this.state.lessonSubject}
                language={this.language}
                // isValidated={this.state.validateLessonSubject}
              />
              { this.state.validateLessonSubject ? <span className="text-danger"><FormattedMessage id="va-lesson-radios"/></span> : ""}
              <RadioButtonGrades
                textName="lessonGrade"
                onChange={this.onChange}
                content={this.state.lessonGrade}
                language={this.language}
                // isValidated={this.state.validateLessonGrade}
              />
               { this.state.validateLessonGrade ? <span className="text-danger"><FormattedMessage id="va-lesson-radios"/></span> : ""}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={this.onCloseModal}>
              <FaArrowLeft /><FormattedMessage id="d-cancel-diagnostic"/>
            </Button>
            <Button variant="warning" onClick={this.createDiagnosticSubjectLevel} disabled={ this.state.lessonSubject === "" || this.state.lessonGrade === ""}>
              <FaSave /> <FormattedMessage id="d-save-diagnostic"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ModalDiagnosticCreate;
const apiUrl = process.env.REACT_APP_BASE_URL;

export const ROLE_STR_ADMIN = 'Admin';
export const ROLE_STR_SUB_ADMIN = 'SubAdmin';
export const ROLE_GRAPHIC_DESIGNER = 'Graphic Designer';
export const ROLE_EXTERNAL_COLLABORATOR = 'External Collaborato';
export const ROLE_PROGRAMMING = 'Programming';
export const ROLE_STR_VIDEO_ARTIST = 'VideoArtist';
export const ROLE_SUBJECT_SPECIALIST = 'Subject Specialist';
export const ROLE_VIEW = 'View';

export const DEFAULT_LANGUAGE = 'es';
export const tinyMCEkey = "6z8mpir8ftb4hn9zadn5ebnb76983hbva162wzau28fop9b0";
//File upload
export const uploadLink = apiUrl + "files/upload?";

// Urls for reports
export const apiAuditUrl = apiUrl + "reports/audit/";
export const apiLessonsReportUrl = apiUrl + "reports/lessons/";

// Urls for rules
export const apiRulesUrl = apiUrl + "files/rules/";

//Urls for Users
export const apiLoginUrl = apiUrl + "auth/login";
export const apiGetUsersUrl = apiUrl + "accounts/advanced";
export const apiGetUserDataUrl = apiUrl + "accounts/advanced";
export const apiRegister = apiUrl + "register";
export const apiUserUpdate = apiUrl + "accounts/update";
export const apiPasswordUpdate = apiUrl + "accounts/password/update";
export const apiGetUserRoles = apiUrl + "roles";
export const apiGetPermissions = apiUrl + "permissions";
export const apiCreatePermissions = apiUrl + "permissions/create";
export const apiModifyPermissions = apiUrl + "permissions/modify";
export const apiCreateRoles = apiUrl + "roles/create";
export const apiModifyRoles = apiUrl + "roles/modify";

// Urls for API
export const apiGetApiKeys = apiUrl + "api-keys";
export const apiCreateApiKeys = apiUrl + "api-keys/create";
export const apiModifyApiKeys = apiUrl + "api-keys/modify";

//Urls for additional material
export const apiAdditionalMaterialFileUploadUrl = apiUrl + "additional-material/files/upload/";
export const apiAdditionalMaterialCreateUrl = apiUrl + "additional-material/create/";
export const apiAdditionalMaterialModifyUrl = apiUrl + "additional-material/modify/";
export const apiAdditionalMaterialDeleteUrl = apiUrl + "additional-material/delete/";
export const apiAdditionalMaterialSearchUrl = apiUrl + "additional-material/read/";
export const apiAdditionalMaterialSpecificUrl = apiUrl + "additional-material/read/";
export const apiLessonAdditionalMaterialReadUrl = apiUrl + "additional-material/lesson/read/";
export const apiLessonAdditionalMaterialDeleteUrl = apiUrl + "additional-material/lesson/delete/";
export const apiLessonAdditionalMaterialCreateUrl = apiUrl + "additional-material/lesson/create/";

// Urls for offline material
export const apiOfflineMaterialCreateUrl = apiUrl + "offline-material/create/";
export const apiOfflineReadUrl = apiUrl + "offline-material/read/";
export const apiOfflineMaterialDeleteUrl = apiUrl + "offline-material/archive/";

//Urls for Lessons
export const apiLessonsTableUrl = apiUrl + "lessons/advance";
export const apiLessonStatusesUrl = apiUrl + "lessons/statuses";
export const apiLessonGetContent = apiUrl + "lessons/lesson";
export const apiLessonCreate = apiUrl + "lessons/create";
export const apiLessonUpdate = apiUrl + "lessons/update";
export const apiSubjectUrl = apiUrl + "subjects/";
export const apiSubjectCreateUrl = apiUrl + "subjects/create";
export const apiSubjectModifyUrl = apiUrl + "subjects/modify";
export const apiGradeUrl = apiUrl + "grades/";
export const apiGradeCreateUrl = apiUrl + "grades/create";
export const apiGradeModifyUrl = apiUrl + "grades/modify";
export const apiAdressUrl = apiUrl + "address-all";
export const apiTaxonomyVerbs = apiUrl + "taxonomy/verbs";
export const apiUnitsUrl = apiUrl + "unit/grade-unit";
export const apiLessonLanguages = apiUrl + "lessons/languages";
export const apiTemplateUrl = apiUrl + "templates";
export const apiGetLessonRulesUrl = apiUrl + "addressed/rules";
export const apiCreateConstructedCode = apiUrl + "addressed/constructedcode/create";

//Urls for Codes
export const apiGetCodes = apiUrl + "codes/";
export const apiGetCodesByTypes = apiUrl + "codes/byTypeId";
export const apiCreateCode = apiUrl + "codes";
export const apiUpdateCode = apiUrl + "codes";
export const apiGetCodeById = apiUrl + "codes";
export const apiConstructedCodes1 = apiUrl + "constructedcode/create";
export const apiConstructedCodes2 = apiUrl + "ConstructedCodeCodes/create";

export const apiGetCodesByQuery = apiUrl + "codes/query";

export const apiGetTypes = apiUrl + "codes-types";
export const apiCreateCodeType = apiUrl + "codes-types";
export const apiUpdateCodeType = apiUrl + "codes-types";
export const apiGetCodeTypeById = apiUrl + "codes-types";

//Urls for Standard Codes
export const apiGetStandard = apiUrl + "pr-standards/standards/filter/";
export const apiGetExpectations = apiUrl + "pr-standards/expectations/filter";
export const apiGetSubCodes = apiUrl + "pr-standards/subcodes/filter";
export const apiGetSubMainSubCodes = apiUrl + "pr-standards/submain-subcodes/filter";
export const apiGetStandardById = apiUrl + "pr-standards/standards";
export const apiStandardsCreate = apiUrl + "pr-standards/standards/create/";
export const apiStandardModify = apiUrl + "pr-standards/standards/modify/";
export const apiGetExpectationById = apiUrl + "pr-standards/expectations/";
export const apiExpectationCreate = apiUrl + "pr-standards/expectations/create";
export const apiExpectationModify = apiUrl + "pr-standards/expectations/modify";
export const apiGetSubCodesById = apiUrl + "pr-standards/subcodes/";
export const apiSubCodesCreate = apiUrl + "pr-standards/subcodes/create";
export const apiSubCodesModify = apiUrl + "pr-standards/subcodes/modify";
export const apiGetSubMainSubCodeById = apiUrl + "pr-standards/submain-subcodes";
export const apiSubMainSubCodeCreate = apiUrl + "pr-standards/submain-subcodes/create";
export const apiSubMainSubCodeModify = apiUrl + "pr-standards/submain-subcodes/modify";

//Urls for File Uploads
export const apiGetFileRules = apiUrl + "files/rules";
export const apiUploadFiles = apiUrl + "files/upload";

//Urls for Quizzes
export const apiCreateQuizUrl = apiUrl + "quizzes/create";
export const apiGetQuizUrl = apiUrl + "quizzes/quiz";
export const apiUpdateQuizUrl = apiUrl + "quizzes/update";
export const apiGetQuizRules = apiUrl + "quizzes/rules";
export const apiQuizTypeUrl = apiUrl + "quizzes/quiz-type";
export const apiPsychUrl = apiUrl + "taxonomy/psych";
export const apiLevelsUrl = apiUrl + "taxonomy/levels";
export const apiDeleteQuizUrl = apiUrl + "quizzes/delete";

//Urls 
export const apiGetPdfLink = apiUrl + "lessons/print/";
export const apiGetPdfLinkStudents = apiUrl + "lessons/students/print/";

//HTML Viewers
export const apiHtmlLesson = apiUrl + "html/lesson";
export const apiHtmlConcept = apiUrl + "html/concept";
export const apiHtmlDefinition = apiUrl + "html/definition";
export const apiHtmlExample = apiUrl + "html/example";
export const apiHtmlQuiz = apiUrl + "html/quiz";
export const apiHtmlDiagnostic = apiUrl + "html/diagnostic";

//Templates
export const apiGetTemplateCodes = apiUrl + "templates/codes";
export const apiCreateTemplateUrl = apiUrl + "templates/create";
export const apiUpdateTemplateUrl = apiUrl + "templates/update";
export const apiGetTemplates = apiUrl + "templates";
export const apiGetTemplateDetails = apiUrl + "templates/details";

//Comments
export const apiGetReceivedComments = apiUrl + "comments/received";
export const apiGetSentComments = apiUrl + "comments/sent";
export const apiCreateComment = apiUrl + "comments/create";
export const apiReadComment = apiUrl + "comments/update/read";
export const apiCompleteComment = apiUrl + "comments/update/completed";
export const apiGetCommentUsers = apiUrl + "accounts/generic/names";


//Units
export const apiCreateUnit = apiUrl + "unit/create";
export const apiUpdateUnit = apiUrl + "unit/modify";
export const apiGetUnitById = apiUrl + "unit";
export const apiGetUnitsByGradeAndSubject = apiUrl + "unit/grade-unit";

// Learning sheet class
export const apiLearningSheet = apiUrl + "learning-sheet";

// Learning sheet class
export const apiDiagnostic = apiUrl + "diagnostics";
/**
 * Created by Luis Diaz
 * 2019-2020
 * Modified by: Pedro Ortiz
 * Changes: UI improvement, plus user can change the level of knowledge
 */
import React, { Component } from 'react';
import InstructionsInfo from './subComponents/instructionsInfo';
import ExtraContentInfo from './subComponents/extraContentInfo';

class DiagnosticPrePostTestInfo extends Component{
  constructor(props){
    super(props);
    
    this.state={
      instructions: this.props.state.diagnosticTest.Instructions,
      hasExtraContent: this.props.state.diagnosticTest.ExtraInfo ? true : false,
      extraContent: this.props.state.diagnosticTest.ExtraInfo
    }

    this.changeInstructions = this.changeInstructions.bind(this);
    this.extraContentToggle = this.extraContentToggle.bind(this);
    this.changeExtraContent = this.changeExtraContent.bind(this);
  }

  changeInstructions(content){
    this.onChangeDiagnosticTest("Instructions", content);
  }
  
  changeExtraContent(index, content){
    this.onChangeDiagnosticTest("ExtraInfo", content);
  }


  extraContentToggle(){
    this.setState({
      hasExtraContent: !this.state.hasExtraContent
    }, function() {
      if(!this.state.hasExtraContent){
        this.setState({
          extraContent: ""
        });
      }
    });
  }

  onChangeDiagnosticTest(field, value){
    this.props.onChange("diagnosticTest", {
      ...this.props.state.diagnosticTest,
      [field]: value
    });
  }
  
  componentWillReceiveProps(nextProps){
    if(nextProps.state.diagnosticTest.Instructions){
      this.setState({
        instructions: nextProps.state.diagnosticTest.Instructions
      });
    }
    if(nextProps.state.diagnosticTest.ExtraInfo){
      this.setState({
        hasExtraContent: true,
        extraContent: nextProps.state.diagnosticTest.ExtraInfo
      });
    }
  }

  render(){
    return(
      <div className="card-alt-2">
        <br/>
      
        <InstructionsInfo
          onChange={this.changeInstructions}
          content={this.state.instructions}
          limitFromRules={this.props.state.quizRules ? this.props.state.quizRules.InstructionMaxLength : null}
        />

        <ExtraContentInfo
          extraContentToggle={this.extraContentToggle}
          hasExtraContent={this.state.hasExtraContent}
          changeExtraContent={this.changeExtraContent}
          extraContent={this.state.extraContent}
          lessonGrade={this.props.state.lessonGrade}
          lessonSubject={this.props.state.lessonSubject}
          language={this.props.language}
          limitFromRules={this.props.state.quizRules ? this.props.state.quizRules.ExtraInfoMaxLength : null}
          fileRules={this.props.fileRules}
        />
      </div>
    );
  }
}

export default DiagnosticPrePostTestInfo;
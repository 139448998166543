import axios from "../interceptors";
import { apiDiagnostic } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const onDiagnosticTestStatusCall = async function () {

	return axios
		.get(`${apiDiagnostic}/tests/statuses`, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then(function (response) {
			if (response.status === 200 || response.status === 201) {
				return response.data.map((item) => ({
					title: item.Name,
					code: item.Code,
				}));
			} else if (response.status === 401) {
				//session is not valid, user is sent to login
				alert("Session is not valid.");
				logoutUser();
			}
		})
		.catch((error) => {
			console.log(error.response.data.Messages);
		});
};

export const createDiagnosticSubjectLevelCall = async function (language, diagnosticSubjectLevelModel) {

	return axios.post(`${apiDiagnostic}/create`, diagnosticSubjectLevelModel, {
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then(function (response) {
			if (response.status === 200 || response.status === 201) {
				alert(response.data.Messages);
				return true;
			} else if (response.status === 400) {
				alert(response.data.Messages);
				return false;
			} else if (response.status === 401) {
				//session is not valid, user is sent to login
				alert("Session is not valid.");
				logoutUser();
			}
		})
		.catch((error) => {
			console.log(error.response.data.Messages);
		});
};


export const createDiagnosticTestCall = async function (language, diagnosticTestModel) {

	return axios.post(`${apiDiagnostic}/tests`, diagnosticTestModel, {
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then(function (response) {
			if (response.status === 200 || response.status === 201) {
				alert(response.data.Messages);
				return true;
			} else if (response.status === 400) {
				alert(response.data.Messages);
				return false;
			} else if (response.status === 401) {
				//session is not valid, user is sent to login
				alert("Session is not valid.");
				logoutUser();
			}
		})
		.catch((error) => {
			console.log(error.response.data.Messages);
		});
};

export const getDiagnosticSubjectLevelsCall = async function (diagnosticSubjectLevelReadModel) {

	return axios.post(`${apiDiagnostic}/read`, diagnosticSubjectLevelReadModel, {
		headers: {
			'Content-Type': 'application/json',
		}
	}).then(function (response) {
		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else if (response.status === 401) {
			//session is not valid, user is sent to login
			alert("Session is not valid.");
			logoutUser();
		}

	}).catch(error => {
		console.log(error.response.data.Messages);
	});
}

export const getDiagnosticSubjectLevelDetailsCall = async function (diagnosticSubjectLevelId, includeFullDetails) {

	return axios
		.get(`${apiDiagnostic}/${diagnosticSubjectLevelId}?includeFullDetails=${includeFullDetails ?? false}`, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then(function (response) {
			if (response.status === 200 || response.status === 201) {
				return response.data;
			} else if (response.status === 401) {
				//session is not valid, user is sent to login
				alert("Session is not valid.");
				logoutUser();
			}
		})
		.catch((error) => {
			console.log(error.response.data.Messages);
		});
};
import React, { Component } from 'react';
import Popout from 'react-popout';
import SessionManager from "../../utils/authHandling/session-manager";
import axios from "../../server/interceptors";
import {
  apiHtmlLesson,
  apiHtmlExample,
  apiHtmlConcept,
  apiHtmlDefinition,
  apiHtmlDiagnostic,
  DEFAULT_LANGUAGE,
  apiHtmlQuiz
} from '../../config';
import {logoutUser} from "../../utils/authHandling/logout-user";

export default class LessonHTMLViewer extends Component{
  constructor(props) {
    super(props);
    this.state={
      contentId: ""
    };
    this.popoutOpen = this.popoutOpen.bind(this);
    this.popoutClosed = this.popoutClosed.bind(this);
    this.getLessonContent = this.getLessonContent.bind(this);
    this.getConceptContent = this.getConceptContent.bind(this);
    this.getDefinitionContent = this.getDefinitionContent.bind(this);
    this.getExampleContent = this.getExampleContent.bind(this);
    this.getQuizContent = this.getQuizContent.bind(this);
    this.getDiagnosticContent = this.getDiagnosticContent.bind(this);
    this.callContent = this.callContent.bind(this);
    this.state = { isPoppedOut: false, contentId: ""};
  }
  removeDonwloadControlFromVideos(htmlContent){
    if(htmlContent.includes("<video")){
      htmlContent = htmlContent.replace("<video","<video controlsList=\"nodownload\"");
    }
    return htmlContent;
  }
  popoutOpen() {
    this.setState({isPoppedOut: true});
  }
  popoutClosed() {
    
    this.setState({
      isPoppedOut: false,
      contentFromServer: null
    });
    this.props.removePreviewId();
  }


  getLessonContent(){
    let self = this;
    axios.get(apiHtmlLesson + "/" + this.props.lessonId, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function (response){
      if(response.status === 401)
      {
        logoutUser();
      }
        const content = self.removeDonwloadControlFromVideos(response.data.HTMLCode);
        self.setState({
          contentFromServer: content,
          isPoppedOut: true
        });
    }).catch(error => {
      console.log(error.response.data.Messages);
    });
  }
  getConceptContent(){
    let self = this;
    axios.get(apiHtmlConcept + "/" + this.props.lessonId, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function (response){
      if(response.status === 401)
      {
        logoutUser();
      }
        const content = self.removeDonwloadControlFromVideos(response.data.HTMLCode);
        console.log(content);
        self.setState({
          contentFromServer: content,
          isPoppedOut: true
        });
    }).catch(error => {
      console.log(error.response.data.Messages);
    });
  }
  getDefinitionContent(){
    let self = this;

    axios.get(apiHtmlDefinition + "/" + this.props.lessonId, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function (response){

      if(response.status === 401)
      {
        logoutUser();
      }
          if(response.data.Link){
            window.open(response.data.Link,"Lesson Content", "width=960,height=720");
            self.props.removePreviewId();
          } else{
            const content = self.removeDonwloadControlFromVideos(response.data.HTMLCode);
            self.setState({
              contentFromServer: content,
              isPoppedOut: true
            });
          }
    }).catch(error => {
      console.log(error.response.data.Messages);
    });
  }
  getExampleContent(){
    let self = this;
    axios.get(apiHtmlExample + "/" + this.props.lessonId, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function (response){

      if(response.status === 401)
      {
        logoutUser();
      }
          if(response.data.Link){
            window.open(response.data.Link,"Lesson Content", "width=960,height=720");
            self.props.removePreviewId();
          }else{
            const content = self.removeDonwloadControlFromVideos(response.data.HTMLCode);
            self.setState({
              contentFromServer: content,
              isPoppedOut: true
            });
          }
    }).catch(error => {
      console.log(error.response.data.Messages);
    });
  }

  getQuizContent(){
    let self = this;
    axios.get(apiHtmlQuiz + "/" + this.props.lessonId, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function (response){

      if(response.status === 401)
      {
        logoutUser();
      }
        const content = self.removeDonwloadControlFromVideos(response.data.HTMLCode);
        self.setState({
          contentFromServer: content,
          isPoppedOut: true
        });

    }).catch(error => {
      console.log(error.response.data.Messages);
    });
  }

  getDiagnosticContent(){
    let self = this;
    // axios.get(apiHtmlDiagnostic + "/" + this.props.lessonId, {
    axios.get(`${apiHtmlDiagnostic}/${this.props.lessonId}`, {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function (response){

      if(response.status === 401)
      {
        logoutUser();
      }
        const content = self.removeDonwloadControlFromVideos(response.data.HTMLCode);
        self.setState({
          contentFromServer: content,
          isPoppedOut: true
        });

    }).catch(error => {
      console.log(error.response.data.Messages);
    });
  }

  callContent(contentId){
    switch(contentId){
      case 0:
        this.getLessonContent();
        this.setState({isPoppedOut: true});
        break;
      case 1:
        this.getConceptContent();
        this.setState({isPoppedOut: true});
        break;
      case 2:
        this.getDefinitionContent();
        // this.setState({isPoppedOut: true});
        break;
      case 3:
        this.getExampleContent();
        // this.setState({isPoppedOut: true});
        break;
      case 4:
        this.getQuizContent();
        this.setState({isPoppedOut: true});
        break;
        case 5:
        this.getDiagnosticContent();
        this.setState({isPoppedOut: true});
        break;
      default:
        break;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.contentId !== null && nextProps.contentId !== undefined && !nextProps.savingLesson){
      this.setState({contentId: nextProps.contentId}, function(){
        this.callContent(this.state.contentId);
      })
    }
  }
  render() {
    if (this.state.isPoppedOut) {    
      return (
        <Popout  
          options={{height: '720', width: '960'}}
          title='Lesson Content' 
          onClosing={this.popoutClosed}
        >
          <div onContextMenu={e =>e.preventDefault()} dangerouslySetInnerHTML={{ __html: this.state.contentFromServer}}></div>
        </Popout>
      );
    }else{
      return(
        <div></div>
      );
    }
  }
}

import React, { Component } from 'react';
import {Row, Col, Button, Card} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import RichTextInput from '../../../../../../components/richTextInput';
import QuestionAnswersList from '../questionAnswerList';
import QuizRubricComponent from '../quizRubricComponent';
import {FaTrash, FaChevronUp, FaChevronDown} from 'react-icons/fa'
import SpinnerLoader from "../../../../../../components/spinnerLoader";
import LearningSheetSimpleSearch
    from "../../../../../viewLearningSheetPage/learning-sheet-simple-search/learning-sheet-simple-search";

class QuestionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileRules: this.props.fileRules,
            isQuestionVisible: true,
            isResponseVisible: true,
        };
    }

    toggleVisibility = (key) => {
        this.setState((prevState) => ({
            [key]: !prevState[key],
        }));
    };

    handleSelectedSheet = (sheet) => {
        this.props.onChangeLearningSheet(sheet);
    }


    render() {
        const { question, deleteQuestion } = this.props;
        const { isQuestionVisible, isResponseVisible, isLearningSheetVisible } = this.state;

        if (
            typeof this.state.fileRules === 'undefined' ||
            this.state.fileRules === null ||
            this.state.fileRules === []
        ) {
            return <SpinnerLoader />;
        }

        return (
            <div>
                {/* Question */}
                <Card className="quiz-card-shadow">
                    <Card.Header className="quiz-card-header">
                        <Row>
                            <Col xs={5}>
                                <h3>
                                    <FormattedMessage id="qp-question" /> {this.props.question.index + 1}
                                </h3>
                            </Col>
                            <Col xs={7} style={{ textAlign: 'right' }}>
                                <Button
                                    variant="danger"
                                    onClick={() => deleteQuestion(question.index)}
                                    style={{ marginRight: '10px' }}
                                >
                                    <FaTrash /> <FormattedMessage id="qp-delete-question" />
                                </Button>
                                <button
                                    className="btn-transparent-primary"
                                    onClick={() => this.toggleVisibility('isQuestionVisible')}
                                >
                                    {isQuestionVisible ? <FaChevronUp /> : <FaChevronDown />}
                                </button>
                            </Col>
                        </Row>
                    </Card.Header>
                    {isQuestionVisible && (
                        <Card.Body>
                            <RichTextInput
                                size={230}
                                subject={this.props.lessonSubject}
                                grade={this.props.lessonGrade}
                                onChange={this.props.onChangeQuestion}
                                content={this.props.quizQuestionModel ? this.props.quizQuestionModel : ""}
                                limitFromRules={this.props.quizRules ? this.props.quizRules.QuestionMaxLength : null}
                                fileRules={this.state.fileRules}
                            />
                        </Card.Body>
                    )}
                </Card>
                <hr />
                {/* Learning sheet */ }
                <Card className="quiz-card-shadow">
                    <Card.Header className="quiz-card-header">
                        <Row>
                            <Col xs={8}>
                                <h4>
                                    <FormattedMessage id="qp-learning-sheet" />
                                </h4>
                            </Col>
                            <Col xs={4} style={{ textAlign: 'right' }}>
                                <button
                                    className="btn-transparent-primary"
                                    onClick={() => this.toggleVisibility('isLearningSheetVisible')}
                                >
                                    {isLearningSheetVisible ? <FaChevronUp /> : <FaChevronDown />}
                                </button>
                            </Col>
                        </Row>
                    </Card.Header>
                    {isLearningSheetVisible && (
                        <Card.Body>
                            <LearningSheetSimpleSearch updateParent={this.handleSelectedSheet}
                                                       showSelectedButton={true}
                                                       key={this.props.learningSheetId}
                                                       selectedId={this.props.learningSheetId}

                            />
                        </Card.Body>
                    )}
                </Card>
                <hr />

                {/* Response */}
                <Card className="quiz-card-shadow">
                    <Card.Header className="quiz-card-header">
                        <Row>
                            <Col xs={8}>
                                <h4>
                                    <FormattedMessage id="qp-response" />
                                </h4>
                            </Col>
                            <Col xs={4} style={{ textAlign: 'right' }}>
                                <button
                                    className="btn-transparent-primary"
                                    onClick={() => this.toggleVisibility('isResponseVisible')}
                                >
                                    {isResponseVisible ? <FaChevronUp /> : <FaChevronDown />}
                                </button>
                            </Col>
                        </Row>
                    </Card.Header>
                    {isResponseVisible && (
                        <Card.Body>
                            <QuestionAnswersList
                                addNewAnswer={this.props.addNewAnswer}
                                onChangeAnswer={this.props.onChangeAnswer}
                                onChangeCorrectStatus={this.props.onChangeCorrectAnswerStatus}
                                responses={this.props.quizQuestionResponseModelList}
                                deleteAnswer={this.props.deleteAnswer}
                                lessonSubject={this.props.lessonSubject}
                                lessonGrade={this.props.lessonGrade}
                                quizRules={this.props.quizRules}
                                fileRules={this.state.fileRules}
                            />
                            {/* Rubric */}
                            {this.props.quizLevel >= 3 ? (
                                <div>
                                    <QuizRubricComponent
                                        content={this.props.rubricContent}
                                        onChange={this.props.onChangeRubricContent}
                                        fileRules={this.state.fileRules}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </Card.Body>
                    )}
                </Card>
            </div>
        );
    }
}

export default QuestionComponent;
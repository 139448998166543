import React, { Component } from 'react';
import TextAreaInput from '../../../../../../components/textAreaInput';
import { FormattedMessage } from 'react-intl';

class InstructionsInfo extends Component{
  render(){
    const { limitFromRules, content } = this.props;
    return(
      <div>
        <b><FormattedMessage id="qp-instructions"/></b>
        <TextAreaInput
          textType="textarea"
          content={content}
          onChange={this.props.onChange}
        />
        {
          limitFromRules ? <small style={content ? content.length > limitFromRules ? {color: "red"} : {} : {}}>{content ? content.length : 0} / {limitFromRules}</small> : ""
        }
      </div>
    );
  }
}

export default InstructionsInfo;
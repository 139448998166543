import React, { Component } from 'react';
import RichTextInput from '../../../../../../components/richTextInput';

import {Col, Row, Button, Card} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { FormattedMessage, injectIntl} from 'react-intl';
import {FaTrash} from 'react-icons/fa';

class Answer extends Component{
  render(){
    const { intl } = this.props;
    return(
      <div>
          <Card className="quiz-card-shadow">
          <Card.Header className="quiz-card-header">
              <Row>
                  <Col xs={5}>
                      <h5 style={{marginRight: "10px"}}><FormattedMessage id="qp-answer-title"/> #{this.props.index + 1}</h5>
                      <Form.Check
                          inline
                          label={intl.formatMessage({id: 'qp-correct-answer'})}
                          name="checkbox"
                          checked={this.props.correctStatus === "1"}
                          onChange={() => this.props.onChangeCorrectStatus(this.props.index)}
                      />
                  </Col>
                  <Col xs={7} style={{textAlign: 'right'}}>
                      <Button variant="danger" onClick={() => this.props.deleteAnswer(this.props.index)}>
                          <FaTrash/>{' '}
                          <FormattedMessage id="qp-delete-answer"/>
                      </Button>
                  </Col>
              </Row>
          </Card.Header>
           <Card.Body>
        <RichTextInput 
          isQuiz
          size={200}
          content={this.props.answerResponseContent} 
          textName={this.props.index} 
          parentStateKey={this.props.key} 
          onChange={this.props.onChangeAnswerContent}
          subject={this.props.lessonSubject}
          grade={this.props.lessonGrade}
          limitFromRules={this.props.limitFromRules}
          fileRules={this.props.fileRules}
        />
           </Card.Body>
          </Card>
      </div>
    );
  }
}

export default injectIntl(Answer);
import React, {useEffect, useState} from "react";
import {Table, Button, Modal, Form, InputGroup, Card} from "react-bootstrap";
import {FaEdit, FaTrash, FaSearch, FaEye, FaPlus, FaCheckCircle} from "react-icons/fa";
import RichTextInput from "../../../components/richTextInput";
import {
    deleteLearningSheet,
    getLearningSheets,
    postLearningSheet,
    putLearningSheet
} from "../../../server/learningSheetsCall/learning-sheets-call";
import {FormattedMessage, useIntl} from "react-intl";
import SpinnerLoader from "../../../components/spinnerLoader";
import Pagination from "@material-ui/lab/Pagination";

/**
 * The learning search component, including the edition, deletion and creation of learning sheets.
 * @param showSelectedButton boolean. True to show the selected button, else false.
 * @param selectedId The selected learning sheet id.
 * @param updateParent The function to update the parent component.
 * @returns {JSX.Element}
 * @constructor
 */
const LearningSheetSimpleSearch = ({showSelectedButton, selectedId, updateParent}) => {
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [selectedLearningSheet, setSelectedLearningSheet] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal]  = useState(false);
    const [id, setSelectedId] = useState(selectedId);
    const [fromPage, setFromPage] = useState(0);
    const [limiter, setLimiter] = useState(5);
    const [data, setData] = useState(null);
    const intl = useIntl();
    const [loading, setLoading] = useState(false);


    const handleSearch = () => {
        setFromPage(0); // Reset the page to 0
        setSelectedId(null);
        onSearch(null, searchKeyword, 0, limiter);
    };

    const handleSave = () => {
        onSave(selectedLearningSheet);
        setShowEditModal(false);
        setSelectedId(null);
    };

    const onSave = (selectedLearningSheet) => {
        setLoading(true);
        // Put if Id is not null
        if (typeof selectedLearningSheet.Id !== undefined &&
            selectedLearningSheet.Id !== null && selectedLearningSheet.Id !== '') {
            putLearningSheet(selectedLearningSheet.Id, selectedLearningSheet.KeyWord, selectedLearningSheet.TextDescription, selectedLearningSheet.TextImportance).then((response) => {
                onSearch(null, searchKeyword, fromPage, limiter);
            }).catch((error) => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            // Else create a new one
            postLearningSheet(selectedLearningSheet.KeyWord, selectedLearningSheet.TextDescription, selectedLearningSheet.TextImportance).then((response) => {
                onSearch(null, searchKeyword, fromPage, limiter);
            }).catch((error) => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const onDelete = (id) => {
        setLoading(true);
        deleteLearningSheet(id).then((response) => {
            setData(null);
            setShowDeleteModal(false);
            setSearchKeyword('');
            setFromPage(0);
            onSearch(null,null, 0, limiter);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
            setSelectedId(null);
        });
    }

    const onSearch = (selectedSheetId, keywordParam, fromPageParam, limiterParam) => {
        setLoading(true);
        setSearchKeyword(keywordParam);
        getLearningSheets(selectedSheetId, keywordParam, fromPageParam, limiterParam).then((response) => {
            setData(response);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }

    const onDescriptionChange = (field, value) => {
        setSelectedLearningSheet(
            {
                ...selectedLearningSheet, TextDescription: value
            });
    }

    const onTextImportanceChange = (field, value) => {
        setSelectedLearningSheet(
            {
                ...selectedLearningSheet, TextImportance: value
            });
    }

    const handlePageChange = (event, page) => {
        setFromPage(page - 1); // Adjust for 0-based indexing if necessary
        setLoading(true); // Set loading state to true before fetching data

        // Fetch the new data for the selected page
        getLearningSheets(id, searchKeyword, page - 1, limiter) // Adjust for 0-based page
            .then((response) => {
                setData(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        onSearch(id, searchKeyword, fromPage, limiter);
    }, []);

    const handleSelectedSheet = (sheet) => {
        updateParent(sheet);
        setSelectedId(sheet.Id);
    }

    const handleUnselectedSheet = (sheet) => {
        setSelectedId(null);
        updateParent(null);
    }

    const hasInvalidContent = (text) => {
        if (!text.trim()) return true; // Empty or whitespace-only content
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");
        return doc.querySelector("img, table, math") !== null; // Check for <img> or <table>
    };

    return (
        <>
            <div className="learning-sheets-table">
                {/* Search Bar */}
                <InputGroup className="mb-3">
                    <Form.Control
                        style={{ maxWidth: "300px" }}
                        type="text"
                        maxLength={200}
                        placeholder={intl.formatMessage({ id: "ls-search-by-keyword" })}
                        value={searchKeyword}
                        onChange={(e) => { setSearchKeyword(e.target.value)} }
                    />

                    <Button style={{marginLeft: 10}} variant="primary" onClick={handleSearch}>
                        <FaSearch />  <FormattedMessage id="ls-search"/>
                    </Button>
                </InputGroup>

                {/* Add New Learning Sheet Button */}
                <Button
                    variant="success"
                    className="mb-3"
                    onClick={() => {
                        setSelectedLearningSheet({ Id: null, KeyWord: "", TextDescription: "", TextImportance: "" });
                        setShowEditModal(true);
                    }}
                >
                    <FaPlus />  <FormattedMessage id="ls-create"/>
                </Button>

                {loading ?
                    <div style={{width: "100%", textAlign: "center"}}>
                        <SpinnerLoader />
                    </div>
                    :
                    <>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th><FormattedMessage id="ls-keyword"/></th>
                            <th><FormattedMessage id="ls-description"/></th>
                            <th><FormattedMessage id="ls-importance"/></th>
                            <th><FormattedMessage id="ls-actions"/></th>
                        </tr>
                        </thead>
                        <tbody>


                        {data != null && data.Items.map((item) => (
                            <tr key={item.Id}>
                                <td>{item.KeyWord}</td>
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            hasInvalidContent(item.TextDescription)
                                                ? intl.formatMessage({ id: "ls-no-preview-available-press-view-button-to-see-full-content" })
                                                : item.TextDescription.length > 100
                                                    ? `${item.TextDescription.slice(0, 100)}...`
                                                    : item.TextDescription,
                                    }}
                                ></td>
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            hasInvalidContent(item.TextImportance)
                                                ? intl.formatMessage({ id: "ls-no-preview-available-press-view-button-to-see-full-content" })
                                                : item.TextImportance.length > 100
                                                    ? `${item.TextImportance.slice(0, 100)}...`
                                                    : item.TextImportance,
                                    }}
                                ></td>
                                <td>
                                    <button
                                        className="not-so-rounded btn-transparent btn-transparent-primary"
                                        disabled={loading}
                                        onClick={() => {
                                            setSelectedLearningSheet(item);
                                            setShowDetailsModal(true);
                                        }}
                                    >
                                        <FaEye/> <FormattedMessage id="ls-view"/>
                                    </button>
                                    {!showSelectedButton ?
                                        <>
                                            <button
                                                className="not-so-rounded btn-transparent btn-transparent-warning"
                                                disabled={loading}
                                                onClick={() => {
                                                    setSelectedLearningSheet(item);
                                                    setShowEditModal(true);
                                                }}
                                            >
                                                <FaEdit/> <FormattedMessage id="ls-edit"/>
                                            </button>
                                            <button
                                                disabled={loading}
                                                className="not-so-rounded btn-transparent btn-transparent-danger"
                                                onClick={() => {
                                                    setSelectedLearningSheet(item);
                                                    setShowDeleteModal(true);
                                                }}
                                            >
                                                <FaTrash/> <FormattedMessage id="ls-delete"/>
                                            </button>
                                        </> : null}
                                    {showSelectedButton ?
                                        <>
                                            {id === item.Id ?
                                                <button disabled={loading}
                                                        className="not-so-rounded btn-transparent btn-transparent-primary"
                                                        onClick={() => {
                                                            handleUnselectedSheet(item);
                                                        }}>
                                                         <span style={{color: "green"}}>
                                                    <FaCheckCircle/> <FormattedMessage id="ls-selected"/></span>

                                                </button>
                                                : <button disabled={loading}
                                                          className="not-so-rounded btn-transparent btn-transparent-primary"
                                                          onClick={() => {
                                                              handleSelectedSheet(item);
                                                          }}>
                                                    <FaCheckCircle/> <FormattedMessage id="ls-select"/>
                                                </button>
                                            }
                                        </> : null}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                        <Pagination
                            count={data?.TotalPages}
                            page={fromPage + 1}
                            onChange={handlePageChange}
                        />
                    </>
                }

                {/* Details Modal */
                }
                <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title> <FormattedMessage id="ls-learning-sheet-to-work-gap"/></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedLearningSheet && (
                            <div>
                                <Card>
                                    <Card.Header>
                                        <b><FormattedMessage id="ls-competency"/>: {selectedLearningSheet.KeyWord}</b>
                                    </Card.Header>
                                    <Card.Body>
                                        <FormattedMessage id="ls-according-to-results"/>
                                        <b> {selectedLearningSheet.KeyWord}. </b>{' '}
                                        <FormattedMessage id="ls-according-to-results-2" />
                                    </Card.Body>
                                </Card>
                                <div style={{marginTop: 20, padding: 15}}>
                                    <p> <b><FormattedMessage id="ls-description" />: </b>
                                        <span  dangerouslySetInnerHTML={{
                                            __html: selectedLearningSheet.TextDescription}} /></p>
                                </div>
                                <Card>
                                    <Card.Header>
                                        <b><FormattedMessage id="ls-why-is-it-important" /></b>
                                    </Card.Header>
                                    <Card.Body>
                                      <span  dangerouslySetInnerHTML={{
                                          __html: selectedLearningSheet.TextImportance}} />
                                    </Card.Body>
                                </Card>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
                            <FormattedMessage id="ls-close" />
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Edit Modal */}
                <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedLearningSheet?.Id ? <FormattedMessage id="ls-edit-learning-sheet" /> :
                            <FormattedMessage id="ls-add-learning-sheet" /> }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedLearningSheet && (
                            <Form>
                                <Form.Group className="mb-3">
                                    <b><Form.Label> <FormattedMessage id="ls-keyword" /></Form.Label></b>
                                    <Form.Control
                                        type="text"
                                        maxLength={200}
                                        value={selectedLearningSheet.KeyWord}
                                        onChange={(e) =>
                                            setSelectedLearningSheet({ ...selectedLearningSheet, KeyWord: e.target.value })
                                        }
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <b><Form.Label> <FormattedMessage id="ls-description" /></Form.Label></b>
                                    <RichTextInput content={selectedLearningSheet.TextDescription} size={200}
                                                   showFileUpload={false}
                                                   onChange={onDescriptionChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <b><Form.Label><FormattedMessage id="ls-importance" /></Form.Label></b>
                                    <RichTextInput content={selectedLearningSheet.TextImportance} size={200}
                                                   showFileUpload={false}
                                                   onChange={onTextImportanceChange}/>
                                </Form.Group>
                            </Form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                            <FormattedMessage id="ls-cancel" />
                        </Button>
                        <Button variant="primary" disabled={loading || selectedLearningSheet === null
                        || selectedLearningSheet.TextDescription === null ||
                            selectedLearningSheet.TextDescription.trim().length === 0 ||
                            selectedLearningSheet.TextDescription === "" ||
                            selectedLearningSheet.TextDescription.trim() === "" ||
                            selectedLearningSheet.TextImportance === null ||
                            selectedLearningSheet.TextImportance.trim().length === 0 ||
                            selectedLearningSheet.TextImportance === "" ||
                            selectedLearningSheet.TextImportance.trim() === "" ||
                            selectedLearningSheet.KeyWord === null ||
                            selectedLearningSheet.KeyWord.trim().length === 0 ||
                            selectedLearningSheet.KeyWord === "" ||
                            selectedLearningSheet.KeyWord.trim() === ""
                        } onClick={handleSave}>
                            <FormattedMessage id="ls-save" />
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete Modal */}
                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title> <FormattedMessage id="ls-confirm-deletion" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ?
                            <div style={{width: "100%", textAlign: "center"}}>
                                <SpinnerLoader />
                            </div> :
                            <>
                                <FormattedMessage id="ls-confirm-delete" />
                                <br/>
                                <b>{selectedLearningSheet?.KeyWord}</b>
                            </> }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" disabled={loading} onClick={() => setShowDeleteModal(false)}>
                            <FormattedMessage id="ls-cancel" />
                        </Button>
                        <Button variant="danger" disabled={loading} onClick={() => onDelete(selectedLearningSheet?.Id)}>
                            <FormattedMessage id="ls-delete" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default LearningSheetSimpleSearch;
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';

import { ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN, ROLE_GRAPHIC_DESIGNER, ROLE_EXTERNAL_COLLABORATOR, ROLE_PROGRAMMING, ROLE_SUBJECT_SPECIALIST, ROLE_VIEW } from '../../config';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';

import '../../assets/styles/nav-bar-style.css';
import image from '../../assets/images/Genials-Logo-White.svg'
import SessionManager from "../../utils/authHandling/session-manager";

class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLogin: SessionManager.getSession() !== null,
            roles: SessionManager.getSession() !== null ? SessionManager.getSessionDetails().Roles : [],
        }
    }

    logout = () => {
        SessionManager.deleteSession();
        this.props.refresh();
    };
    IsRoleAllowed = (allowRoles) => {
        let roles = this.state.roles;
        return allowRoles.some(role => roles.includes(role))
    };
    IsRoleDenied = (deniedRoles) => {
        let roles = this.state.roles;
        return !deniedRoles.some(role => roles.includes(role))
    };
    render() {
        //allow access to routes
        const allowRolesAudit = [ROLE_STR_ADMIN],
            allowRolesSubjects = [ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN],
            allowRolesCreateLesson = [ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN, ROLE_EXTERNAL_COLLABORATOR, ROLE_PROGRAMMING, ROLE_SUBJECT_SPECIALIST],
            allowRolesTemplates = [ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN, ROLE_PROGRAMMING, ROLE_GRAPHIC_DESIGNER],
            allowRolesLevels = [ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN],
            allowRolesUnits = [ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN, ROLE_EXTERNAL_COLLABORATOR, ROLE_PROGRAMMING, ROLE_SUBJECT_SPECIALIST],
            allowRolesAdminSection = [ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN],
            allowRolesLearningSheets =  [ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN, ROLE_EXTERNAL_COLLABORATOR, ROLE_PROGRAMMING, ROLE_SUBJECT_SPECIALIST],
            //denied access to routes
            deniedRolesRules = [ROLE_VIEW],
            deniedRolesAdditionalMaterial = [ROLE_VIEW],
            allowRolesDiagnostic = [ROLE_STR_ADMIN, ROLE_STR_SUB_ADMIN, ROLE_EXTERNAL_COLLABORATOR, ROLE_PROGRAMMING, ROLE_SUBJECT_SPECIALIST],
            { intl } = this.props;

        return (
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
                <Navbar.Brand>
                    <a href="" style={{ textDecoration: 'none' }}>
                        <img src={image} alt="Genial Lessons v2" height="30px" width="auto" />
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className=" mr-auto" justify="true">
                        <NavDropdown title={intl.formatMessage({ id: 'nav-lessons' })} id="nav-dropdown">
                            <LinkContainer to="/view-lessons">
                                <NavDropdown.Item><FormattedMessage id="nav-view" /></NavDropdown.Item>
                            </LinkContainer>
                            {this.IsRoleAllowed(allowRolesCreateLesson) ?
                                <LinkContainer to="/create-lesson">
                                    <NavDropdown.Item><FormattedMessage id="nav-create" /></NavDropdown.Item>
                                </LinkContainer> : null
                            }
                        </NavDropdown>
                        {this.IsRoleAllowed(allowRolesTemplates) ?
                            <NavDropdown title={intl.formatMessage({ id: 'nav-template' })} id="nav-dropdown">
                                <LinkContainer to="/view-templates">
                                    <NavDropdown.Item><FormattedMessage id="nav-view" /></NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/create-template">
                                    <NavDropdown.Item><FormattedMessage id="nav-create" /></NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown> : null
                        }
                        {this.IsRoleAllowed(allowRolesAudit) ?
                            <Nav.Item>
                                <LinkContainer to="/audits">
                                    <Nav.Link className="nav-link">
                                        <FormattedMessage id="nav-audit" />
                                    </Nav.Link>
                                </LinkContainer>
                            </Nav.Item> : null
                        }
                        {this.IsRoleDenied(deniedRolesRules) ?
                            <Nav.Item>
                                <LinkContainer to="/rules">
                                    <Nav.Link className="nav-link">
                                        <FormattedMessage id="nav-rules" />
                                    </Nav.Link>
                                </LinkContainer>
                            </Nav.Item> : null
                        }
                        {this.IsRoleDenied(deniedRolesAdditionalMaterial) ?
                            <NavDropdown title={intl.formatMessage({ id: 'nav-additional-material' })} id="nav-dropdown">
                                <LinkContainer to="/additional-material">
                                    <NavDropdown.Item><FormattedMessage id="nav-view" /></NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/create-additional-material">
                                    <NavDropdown.Item><FormattedMessage id="nav-create" /></NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown> : null
                        }
                        {this.IsRoleAllowed(allowRolesSubjects) ?
                            <NavDropdown title={intl.formatMessage({ id: 'nav-subjects' })} id="nav-dropdown">
                                <LinkContainer to="/view-subjects">
                                    <NavDropdown.Item><FormattedMessage id="nav-view" /></NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/create-subject">
                                    <NavDropdown.Item><FormattedMessage id="nav-create" /></NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown> : null
                        }
                        {this.IsRoleAllowed(allowRolesLevels) ?
                            <NavDropdown title={intl.formatMessage({ id: 'nav-levels' })} id="nav-dropdown">
                                <LinkContainer to="/view-levels">
                                    <NavDropdown.Item><FormattedMessage id="nav-view" /></NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/create-level">
                                    <NavDropdown.Item><FormattedMessage id="nav-create" /></NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown> : null
                        }
                        {this.IsRoleAllowed(allowRolesUnits) ?
                            <NavDropdown title={intl.formatMessage({ id: 'nav-codes' })} id="nav-dropdown">
                                <LinkContainer to="/create-code">
                                    <NavDropdown.Item><FormattedMessage id="nav-codes-create" /></NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/view-codes">
                                    <NavDropdown.Item><FormattedMessage id="nav-codes-edit" /></NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/create-type">
                                    <NavDropdown.Item><FormattedMessage id="nav-type-create" /></NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/view-types">
                                    <NavDropdown.Item><FormattedMessage id="nav-type-edit" /></NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown> : null
                        }
                        {this.IsRoleAllowed(allowRolesUnits) ?
                            <NavDropdown title={intl.formatMessage({ id: 'nav-standards' })} id="nav-dropdown">
                                <LinkContainer to="/view-units">
                                    <NavDropdown.Item><FormattedMessage id="nav-edit-units" /></NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/create-units">
                                    <NavDropdown.Item><FormattedMessage id="nav-create-units" /></NavDropdown.Item>
                                </LinkContainer>
                                {/*<LinkContainer to="/view-standards">*/}
                                {/*  <NavDropdown.Item><FormattedMessage id="nav-standards-edit"/></NavDropdown.Item>*/}
                                {/*</LinkContainer>*/}
                                {/*<LinkContainer to="/create-standard">*/}
                                {/*  <NavDropdown.Item><FormattedMessage id="nav-standards-create"/></NavDropdown.Item>*/}
                                {/*</LinkContainer>*/}
                                {/*<LinkContainer to="/view-expectations">*/}
                                {/*  <NavDropdown.Item><FormattedMessage id="nav-expectations-edit"/></NavDropdown.Item>*/}
                                {/*</LinkContainer>*/}
                                {/*<LinkContainer to="/create-expectation">*/}
                                {/*  <NavDropdown.Item><FormattedMessage id="nav-expectations-create"/></NavDropdown.Item>*/}
                                {/*</LinkContainer>*/}
                                {/*<LinkContainer to="/view-indicators">*/}
                                {/*  <NavDropdown.Item><FormattedMessage id="nav-indicators-edit"/></NavDropdown.Item>*/}
                                {/*</LinkContainer>*/}
                                {/*<LinkContainer to="/create-indicator">*/}
                                {/*  <NavDropdown.Item><FormattedMessage id="nav-indicators-create"/></NavDropdown.Item>*/}
                                {/*</LinkContainer>*/}
                                {/*<LinkContainer to="/view-sub-indicators">*/}
                                {/*  <NavDropdown.Item><FormattedMessage id="nav-sub-indicators-edit"/></NavDropdown.Item>*/}
                                {/*</LinkContainer>*/}
                                {/*<LinkContainer to="/create-sub-indicator">*/}
                                {/*  <NavDropdown.Item><FormattedMessage id="nav-sub-indicators-create"/></NavDropdown.Item>*/}
                                {/*</LinkContainer>*/}
                            </NavDropdown> : null
                        }
                        {this.IsRoleAllowed(allowRolesDiagnostic) ?
                            <Nav.Item>
                                <LinkContainer to="/diagnostic">
                                    <Nav.Link className="nav-link">
                                        <FormattedMessage id="nav-diagnostic" />
                                    </Nav.Link>
                                </LinkContainer>
                            </Nav.Item> : null
                        }
                        {this.IsRoleAllowed(allowRolesLearningSheets) ?
                            <Nav.Item>
                                <LinkContainer to="/learning-sheets">
                                    <Nav.Link className="nav-link">
                                        <FormattedMessage id="nav-learning-sheets" />
                                    </Nav.Link>
                                </LinkContainer>
                            </Nav.Item> : null
                        }
                        {this.IsRoleAllowed(allowRolesAdminSection) ?
                            <NavDropdown title={intl.formatMessage({ id: 'nav-access-api' })} id="nav-dropdown">
                                <LinkContainer to="/roles">
                                    <NavDropdown.Item><FormattedMessage id="nav-roles" /></NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/permissions">
                                    <NavDropdown.Item><FormattedMessage id="nav-permissions" /></NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/apis">
                                    <NavDropdown.Item><FormattedMessage id="nav-api-keys" /></NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown> : null
                        }
                        {this.IsRoleAllowed(allowRolesAdminSection) ?
                            <NavDropdown title={intl.formatMessage({ id: 'nav-users' })} id="nav-dropdown">
                                <LinkContainer to="/view-users">
                                    <NavDropdown.Item><FormattedMessage id="nav-view" /></NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/create-users">
                                    <NavDropdown.Item><FormattedMessage id="nav-create" /></NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown> : null
                        }
                        <NavDropdown title={intl.formatMessage({ id: 'nav-language' })} id="nav-dropdown">
                            <NavDropdown.Item onClick={this.props.changeToES}><FormattedMessage id="nav-spanish" /></NavDropdown.Item>
                            <NavDropdown.Item onClick={this.props.changeToEN}><FormattedMessage id="nav-english" /></NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Item>
                            <Nav.Link onClick={this.logout} to="./">
                                <FormattedMessage id="nav-logout" />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default injectIntl(NavBar);